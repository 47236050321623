/**
 * router/index.ts
 *
 * Automatic routes for `./src/pages/*.vue`
 */

// Composables
import { createRouter, createWebHistory } from 'vue-router'

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes: [
    {
      path: "/",
      name: "Home",
      component: () => import("../pages/index.vue")
    },
    {
      path: "/shop/:id",
      name: "Shop",
      component: () => import("../pages/shopinfo.vue")
    },
    {
      path: "/machine/:id",
      name: "Machine",
      component: () => import("../pages/machineinfo.vue")
    },
    {
      path: "/machine/:id/:machineinfo_id",
      name: "MachineInfo",
      component: () => import("../pages/machineinfo.vue")
    },
    {
      path: "/shop/:id/edit",
      name: "EditShop",
      component: () => import("../pages/new_shop.vue")
    },
    {
      path: "/machine/:id/edit",
      name: "EditMachine",
      component: () => import("../pages/edit_machine.vue")
    },
    {
      path: "/forgot_password",
      name: "ForgotPassword",
      component: () => import("../pages/forgot_password.vue")
    },
    {
      path: "/reset/:token",
      name: "ResetPassword",
      component: () => import("../pages/reset_password.vue")
    },
    {
      path: "/purchase_completed",
      name: "PurchaseCompleted",
      component: () => import("../pages/complete_purchase.vue")
    },
    {
      path: "/shop/:id/edit_history",
      name: "EditShopHistory",
      component: () => import("../pages/shopinfo_edit_history.vue")
    },
    {
      path: "/favorite/shop",
      name: "FavoriteShop",
      component: () => import("../pages/favorite_shops.vue")
    },
    {
      path: "/favorite/machine",
      name: "FavoriteMachine",
      component: () => import("../pages/favorite_machines.vue")
    },
    {
      path: "/images",
      name: "Images",
      component: () => import("../pages/my_images.vue")
    },
    {
      path: "/myshopedit",
      name: "MyShopEdit",
      component: () => import("../pages/shopedit_history.vue")
    },
    {
      path: "/mymachineedit",
      name: "MyMachineEdit",
      component: () => import("../pages/machineedit_history.vue")
    },
    {
      path: "/login",
      name: "Login",
      component: () => import("../pages/login.vue")
    },
    {
      path: "/glogin",
      name: "GLogin",
      component: () => import("../pages/glogin.vue")
    },
    {
      path: "/register",
      name: "Register",
      component: () => import("../pages/register.vue")
    },
    {
      path: "/gregister",
      name: "GRegister",
      component: () => import("../pages/gregister.vue")
    },
    {
      path: "/tokusyo",
      name: "Tokusyo",
      component: () => import("../pages/tokusyo.vue")
    },
    {
      path: "/premium",
      name: "Premium",
      component: () => import("../pages/premium.vue")
    },
    {
      path: "/user/:id",
      name: "User",
      component: () => import("../pages/userpage.vue")
    },
    {
      path: "/mypage",
      name: "Mypage",
      component: () => import("../pages/mypage.vue")
    },
    {
      path: "/myinfo",
      name: "Myinfo",
      component: () => import("../pages/myinfo.vue")
    },
    {
      path: "/admin",
      name: "Admin",
      component: () => import("../pages/admin.vue")
    },
    {
      path: "/medalbank",
      name: "MedalBank",
      component: () => import("../pages/medalbank.vue")
    },
    {
      path: "/new",
      name: "NewShop",
      component: () => import("../pages/new_shop.vue")
    },
    {
      path: "/search",
      name: "Search",
      component: () => import("../pages/search.vue")
    },
    {
      path: "/gpssearch",
      name: "GPSSearch",
      component: () => import("../pages/gps_search.vue")
    },
    {
      path: "/announcement/:id",
      name: "Announcement",
      component: () => import("../pages/announcement.vue")
    },
    {
      path: "/announcement",
      name: "AnnouncementList",
      component: () => import("../pages/announcements.vue")
    },
    {
      path: "/shop/:id/:shopinfo_id",
      name: "ShopInfo",
      component: () => import("../pages/shopinfo.vue")
    },
    {
      path: "/:pathMatch(.*)*",
      name: "NotFound",
      component: () => import("../pages/404.vue")
    }
  ]
})

export default router