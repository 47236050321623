/**
 * plugins/vuetify.ts
 *
 * Framework documentation: https://vuetifyjs.com`
 */

// Styles
import '@mdi/font/css/materialdesignicons.css'
import 'vuetify/styles'
import DateFnsAdapter from '@date-io/date-fns'
import ja from "date-fns/locale/ja"
import enUS from "date-fns/locale/en-US"


// Composables
import { createVuetify } from 'vuetify'

// https://vuetifyjs.com/en/introduction/why-vuetify/#feature-guides
export default createVuetify({
  //
  date: {
    adapter: DateFnsAdapter,
    locale: {
      ja: ja,
      en: enUS,
      fallback: 'en',
    },
  }
})
